<template>
    <Card>
      <template #header>
        <div
          class="d-flex align-items-center justify-content-between"
          style="width: 100%"
        >
          <div>
            <h4>Yeni İhale</h4>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card-sub-header">İhale Bilgileri</div>
        </div>
  
        <!-- ad -->
        <div class="col-lg-6 col-12">
        <div class="form-group">
            <label for="">İhale Başlangıç Tarihi</label>
            <input type="date" class="form-control" />
          </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
            <label for="">İhale Bitiş Tarihi</label>
            <input type="date" class="form-control" />
          </div>
      </div>
        <div class="col-12">
          <b-form-group class="add-style">
            <label for="fiyat">Fiyat </label>
            <b-form-input :type="text" id="fiyat" required />
          </b-form-group>
        </div>
  

      </div>
         <div class="row">
             <div class="col-12">
                <div class="card-sub-header">Sorumlu Bilgileri</div>
             </div>
             <div class="col-lg-6 col-12">
                <b-form-group class="add-style">
                    <label for="ad">Adı Soyadı </label>
                    <b-form-input :type="text" id="ad" required />
                </b-form-group>
             </div>     
            <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Telefon Numarası</label>
                  <masked-input
                    class="form-control form-control-lg"
                    v-model="phone"
                    mask="\+\90 (111) 111-1111"
                    placeholder="+90 (5__) ___-____"
                  />
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <b-form-group class="add-style">
                    <label for="email">Email</label>
                    <b-form-input :type="text" id="email" required />
                </b-form-group>
             </div>
        </div>  
      <button class="saveButton mx-auto mt-5" @click="addRow()">Kaydet</button>
    </Card>
    
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import MaskedInput from 'vue-masked-input';
  export default {
    data() {
      return {
        phone:"",
        options: [
          { text: "Şekerbank", value: "1" },
          { text: "Odeobank", value: "2" },
          { text: "Alternatif Bank", value: "3" },
        ],
        options2: [
          { text: "Konut", value: "1" },
          { text: "Akaryakıt", value: "2" },
        ],
        options3: [
          { text: "Hissedar", value: "1" },
          { text: "İstanbul Varlık", value: "2" },
        ],
        row: [],
        districts: [],
        counties: [
          {
            il: "Adana",
            plaka: 1,
            ilceleri: [
              "Aladağ",
              "Ceyhan",
              "Çukurova",
              "Feke",
              "İmamoğlu",
              "Karaisalı",
              "Karataş",
              "Kozan",
              "Pozantı",
              "Saimbeyli",
              "Sarıçam",
              "Seyhan",
              "Tufanbeyli",
              "Yumurtalık",
              "Yüreğir",
            ],
          },
          {
            il: "Adıyaman",
            plaka: 2,
            ilceleri: [
              "Besni",
              "Çelikhan",
              "Gerger",
              "Gölbaşı",
              "Kahta",
              "Merkez",
              "Samsat",
              "Sincik",
              "Tut",
            ],
          },
          {
            il: "Afyonkarahisar",
            plaka: 3,
            ilceleri: [
              "Başmakçı",
              "Bayat",
              "Bolvadin",
              "Çay",
              "Çobanlar",
              "Dazkırı",
              "Dinar",
              "Emirdağ",
              "Evciler",
              "Hocalar",
              "İhsaniye",
              "İscehisar",
              "Kızılören",
              "Merkez",
              "Sandıklı",
              "Sinanpaşa",
              "Sultandağı",
              "Şuhut",
            ],
          },
          {
            il: "Ağrı",
            plaka: 4,
            ilceleri: [
              "Diyadin",
              "Doğubayazıt",
              "Eleşkirt",
              "Hamur",
              "Merkez",
              "Patnos",
              "Taşlıçay",
              "Tutak",
            ],
          },
          {
            il: "Amasya",
            plaka: 5,
            ilceleri: [
              "Göynücek",
              "Gümüşhacıköy",
              "Hamamözü",
              "Merkez",
              "Merzifon",
              "Suluova",
              "Taşova",
            ],
          },
          {
            il: "Ankara",
            plaka: 6,
            ilceleri: [
              "Altındağ",
              "Ayaş",
              "Bala",
              "Beypazarı",
              "Çamlıdere",
              "Çankaya",
              "Çubuk",
              "Elmadağ",
              "Güdül",
              "Haymana",
              "Kalecik",
              "Kızılcahamam",
              "Nallıhan",
              "Polatlı",
              "Şereflikoçhisar",
              "Yenimahalle",
              "Gölbaşı",
              "Keçiören",
              "Mamak",
              "Sincan",
              "Kazan",
              "Akyurt",
              "Etimesgut",
              "Evren",
              "Pursaklar",
            ],
          },
          {
            il: "Antalya",
            plaka: 7,
            ilceleri: [
              "Akseki",
              "Alanya",
              "Elmalı",
              "Finike",
              "Gazipaşa",
              "Gündoğmuş",
              "Kaş",
              "Korkuteli",
              "Kumluca",
              "Manavgat",
              "Serik",
              "Demre",
              "İbradı",
              "Kemer",
              "Aksu",
              "Döşemealtı",
              "Kepez",
              "Konyaaltı",
              "Muratpaşa",
            ],
          },
          {
            il: "Artvin",
            plaka: 8,
            ilceleri: [
              "Ardanuç",
              "Arhavi",
              "Merkez",
              "Borçka",
              "Hopa",
              "Şavşat",
              "Yusufeli",
              "Murgul",
            ],
          },
          {
            il: "Aydın",
            plaka: 9,
            ilceleri: [
              "Merkez",
              "Bozdoğan",
              "Efeler",
              "Çine",
              "Germencik",
              "Karacasu",
              "Koçarlı",
              "Kuşadası",
              "Kuyucak",
              "Nazilli",
              "Söke",
              "Sultanhisar",
              "Yenipazar",
              "Buharkent",
              "İncirliova",
              "Karpuzlu",
              "Köşk",
              "Didim",
            ],
          },
          {
            il: "Balıkesir",
            plaka: 10,
            ilceleri: [
              "Altıeylül",
              "Ayvalık",
              "Merkez",
              "Balya",
              "Bandırma",
              "Bigadiç",
              "Burhaniye",
              "Dursunbey",
              "Edremit",
              "Erdek",
              "Gönen",
              "Havran",
              "İvrindi",
              "Karesi",
              "Kepsut",
              "Manyas",
              "Savaştepe",
              "Sındırgı",
              "Gömeç",
              "Susurluk",
              "Marmara",
            ],
          },
          {
            il: "Bilecik",
            plaka: 11,
            ilceleri: [
              "Merkez",
              "Bozüyük",
              "Gölpazarı",
              "Osmaneli",
              "Pazaryeri",
              "Söğüt",
              "Yenipazar",
              "İnhisar",
            ],
          },
          {
            il: "Bingöl",
            plaka: 12,
            ilceleri: [
              "Merkez",
              "Genç",
              "Karlıova",
              "Kiğı",
              "Solhan",
              "Adaklı",
              "Yayladere",
              "Yedisu",
            ],
          },
          {
            il: "Bitlis",
            plaka: 13,
            ilceleri: [
              "Adilcevaz",
              "Ahlat",
              "Merkez",
              "Hizan",
              "Mutki",
              "Tatvan",
              "Güroymak",
            ],
          },
          {
            il: "Bolu",
            plaka: 14,
            ilceleri: [
              "Merkez",
              "Gerede",
              "Göynük",
              "Kıbrıscık",
              "Mengen",
              "Mudurnu",
              "Seben",
              "Dörtdivan",
              "Yeniçağa",
            ],
          },
          {
            il: "Burdur",
            plaka: 15,
            ilceleri: [
              "Ağlasun",
              "Bucak",
              "Merkez",
              "Gölhisar",
              "Tefenni",
              "Yeşilova",
              "Karamanlı",
              "Kemer",
              "Altınyayla",
              "Çavdır",
              "Çeltikçi",
            ],
          },
          {
            il: "Bursa",
            plaka: 16,
            ilceleri: [
              "Gemlik",
              "İnegöl",
              "İznik",
              "Karacabey",
              "Keles",
              "Mudanya",
              "Mustafakemalpaşa",
              "Orhaneli",
              "Orhangazi",
              "Yenişehir",
              "Büyükorhan",
              "Harmancık",
              "Nilüfer",
              "Osmangazi",
              "Yıldırım",
              "Gürsu",
              "Kestel",
            ],
          },
          {
            il: "Çanakkale",
            plaka: 17,
            ilceleri: [
              "Ayvacık",
              "Bayramiç",
              "Biga",
              "Bozcaada",
              "Çan",
              "Merkez",
              "Eceabat",
              "Ezine",
              "Gelibolu",
              "Gökçeada",
              "Lapseki",
              "Yenice",
            ],
          },
          {
            il: "Çankırı",
            plaka: 18,
            ilceleri: [
              "Merkez",
              "Çerkeş",
              "Eldivan",
              "Ilgaz",
              "Kurşunlu",
              "Orta",
              "Şabanözü",
              "Yapraklı",
              "Atkaracalar",
              "Kızılırmak",
              "Bayramören",
              "Korgun",
            ],
          },
          {
            il: "Çorum",
            plaka: 19,
            ilceleri: [
              "Alaca",
              "Bayat",
              "Merkez",
              "İskilip",
              "Kargı",
              "Mecitözü",
              "Ortaköy",
              "Osmancık",
              "Sungurlu",
              "Boğazkale",
              "Uğurludağ",
              "Dodurga",
              "Laçin",
              "Oğuzlar",
            ],
          },
          {
            il: "Denizli",
            plaka: 20,
            ilceleri: [
              "Acıpayam",
              "Buldan",
              "Çal",
              "Çameli",
              "Çardak",
              "Çivril",
              "Merkez",
              "Merkezefendi",
              "Pamukkale",
              "Güney",
              "Kale",
              "Sarayköy",
              "Tavas",
              "Babadağ",
              "Bekilli",
              "Honaz",
              "Serinhisar",
              "Baklan",
              "Beyağaç",
              "Bozkurt",
            ],
          },
          {
            il: "Diyarbakır",
            plaka: 21,
            ilceleri: [
              "Kocaköy",
              "Çermik",
              "Çınar",
              "Çüngüş",
              "Dicle",
              "Ergani",
              "Hani",
              "Hazro",
              "Kulp",
              "Lice",
              "Silvan",
              "Eğil",
              "Bağlar",
              "Kayapınar",
              "Sur",
              "Yenişehir",
              "Bismil",
            ],
          },
          {
            il: "Edirne",
            plaka: 22,
            ilceleri: [
              "Merkez",
              "Enez",
              "Havsa",
              "İpsala",
              "Keşan",
              "Lalapaşa",
              "Meriç",
              "Uzunköprü",
              "Süloğlu",
            ],
          },
          {
            il: "Elazığ",
            plaka: 23,
            ilceleri: [
              "Ağın",
              "Baskil",
              "Merkez",
              "Karakoçan",
              "Keban",
              "Maden",
              "Palu",
              "Sivrice",
              "Arıcak",
              "Kovancılar",
              "Alacakaya",
            ],
          },
          {
            il: "Erzincan",
            plaka: 24,
            ilceleri: [
              "Çayırlı",
              "Merkez",
              "İliç",
              "Kemah",
              "Kemaliye",
              "Refahiye",
              "Tercan",
              "Üzümlü",
              "Otlukbeli",
            ],
          },
          {
            il: "Erzurum",
            plaka: 25,
            ilceleri: [
              "Aşkale",
              "Çat",
              "Hınıs",
              "Horasan",
              "İspir",
              "Karayazı",
              "Narman",
              "Oltu",
              "Olur",
              "Pasinler",
              "Şenkaya",
              "Tekman",
              "Tortum",
              "Karaçoban",
              "Uzundere",
              "Pazaryolu",
              "Köprüköy",
              "Palandöken",
              "Yakutiye",
              "Aziziye",
            ],
          },
        ],
        rows: [{}],
        malikCount: 1,
      };
    },

    methods: {
      countyChange(e) {
        let filteredData = this.counties.filter(
          (county) => county.plaka == e.target.value
        );
        this.districts = filteredData[0].ilceleri;
      },
      malikEkle() {
        this.malikCount += 1;
      },
      deleteRow(i) {
        let row = document.getElementById("malik-" + i);
        row.remove();
      },
    },
    components : {
        MaskedInput,
    }
  };
  </script>
  